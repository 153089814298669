.App {
  margin: 0 auto;
  border: 5px solid black;
  padding-bottom: 100px;
}

.form {
  background-image: linear-gradient(-45deg, grey,white);
  height: 50px;
  position: center;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom: 5px solid black;
}

.input {
  font-size: 2rem;
  font-weight: bold;
  border: 5px solid black;
  margin-right: 20px;
}

.button-form {
  font-size: 2rem;
  font-weight: bold;
  border-radius: 30px;
  border: 5px solid black;
}

.button-card {
  font-size: 2rem;
  font-weight: bold;
  border-radius: 30px 30px 0px 30px;
  border: 5px solid black;
}

.container-card {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.todo-card {
  width: 200px;
  border: 5px solid black;
  border-radius: 50px 50px 0px 50px;
  padding: 20px;
  margin: 20px;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(-45deg, grey,white);
  color: black;
}

.todo-card-button {
  width: 80%;
}


.card-top{
  width: 100%;
}

.card-bottom{
  width: 100%;  
}

.task {
  display: flex;
  justify-items: flex-start;
  margin-bottom: 20px;
}

.completed {
  align-content: center;
}

.task.completed {
  color: red;
  text-decoration: line-through red;
  margin-bottom: 20px;
}